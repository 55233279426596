<template>
  <v-dialog
      v-model="dialogTransferResults"
      max-width="800px"
      persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Bitte Name wählen
      </v-card-title>
      <v-card-text>
        <v-data-table
            :headers="headersUsers"
            :items="comp_Searched_User_Names"
            sort-by="Name"
            class="elevation-1 mt-5"
        >
          <template v-slot:top>
            <v-toolbar
                color="blue lighten-5"
                flat
            ><h4>Benutzer</h4>

            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
                class="success my-1 mx-1"
                text
                @click="choosenItem(item)">
              <v-icon>
                mdi-plus-box
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-data-table
            :headers="headersClubs"
            :items="comp_Searched_Clubs_Names"
            sort-by="Name"
            class="elevation-1 mt-5"
        >
          <template v-slot:top>
            <v-toolbar
                color="blue lighten-5"
                flat
            ><h4>Vereine</h4>

            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
                class="success my-1 mx-1"
                text
                @click="choosenItem(item)">
              <v-icon>
                mdi-plus-box
              </v-icon>
            </v-btn>
        </template>

        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
            text
            color="primary"
            @click="closeDialog"
        >abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'weaponTransferSearchedResult',
  props: {
    dialogTransferResults: {
      type: Boolean
    }
  },
  data: () => ({
    headersUsers: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Name', value: 'name'},
      {text: 'Vorname', value: 'vorname'},
      {text: 'Ort', value: 'ort'},
      {text: 'Übernehmen', value: 'actions', sortable: false}
    ],
    headersClubs: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Name', value: 'name'},
      {text: 'Ort', value: 'ort'},
      {text: 'Übernehmen', value: 'actions', sortable: false}
    ],
  }),
  computed: {
    comp_Searched_User_Names() {
      const Data = this.$store.getters['GeneralApp/GET_WeaponTransfer_Searched_Names'];
      return Data.user;
    },
    comp_Searched_Clubs_Names() {
      const Data = this.$store.getters['GeneralApp/GET_WeaponTransfer_Searched_Names'];
      return Data.club;
    }
  },
  methods: {
    closeDialog() {
      this.$emit('clicked', false)
    },
    choosenItem(item){
      this.$emit('assumed', item)
    },
  },
}
</script>

<style scoped>

</style>
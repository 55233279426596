<template>
  <div class="mt-4  px-5">

    <h2>Meine Waffen</h2>
    <v-alert class="info mt-4">
      <b>Tipp:</b> Du kannst dir an jeder Waffe Disziplinen hinterlegen! Somit sparst du Zeit bei der
      Erfassung deiner Schießzeit.
    </v-alert>

    <weapon-table
        :weapon="comp_myWeapons"
        :discipline="comp_AllDiscipline"
        user_club="UserApp"
        @TransferWeaponOpen="TransferWeaponOpen"
        @TransferWeaponReturnDialog="TransferWeaponReturnDialog"
    ></weapon-table>


    <v-dialog v-model="dialogTransferReturn" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Möchtest du die Waffe wieder zurücknehmen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogTransferReturn = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="TransferWeaponReturn">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
        v-model="dialog_Transfer_to"
        width="800"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Waffe übertragen an:
        </v-card-title>
        <v-card-text>
          <v-container>
            <h3 class="mb-3">An wen möchten Sie die Waffe übertragen?</h3>
            <v-alert
                class="mt-3 mb-3"
                type="info"
                outlined
                text>
              Du kannst hier eine Person eingeben, an die du deine Waffe überlässt.<br>
              Über die "Suchen" Funktion <v-icon color="info">mdi-cloud-search</v-icon> die Möglichkeit die Waffe einem schuetzenbuch.de Benutzer zu
              überlassen.<br>
              Wenn du die Waffe an einen Benutzer überlasst, wird dies in deiner und in der anderen Waffenübersicht
              dargestellt.
            </v-alert>
            <v-row>
              <v-col>
                <v-text-field
                    label="Name"
                    v-model="WeaponTransfer.RecName"
                    outlined
                    :rules="[rules.required]"
                    append-icon="mdi-cloud-search"
                    @click:append="find_Transfer_Name"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    label="Vorname"
                    v-model="WeaponTransfer.RecVorname"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Adresse"
                    v-model="WeaponTransfer.RecAddress"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="PLZ"
                    v-model="WeaponTransfer.RecPLZ"
                    outlined
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    label="Ort"
                    v-model="WeaponTransfer.RecOrt"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Überlassen bis"
                    hint="Überlassen max. 4 Wochen!"
                    v-model="WeaponTransfer.RecTransferUntil"
                    outlined
                ></v-text-field>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog_Transfer_to = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="Transfer_get_Reciever"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <weapon-transfer-searched-result
        :dialog-transfer-results="dialogTransferResults"
        @clicked="dialogTransferResults = false"
        @assumed="get_Data_from_Result_Dialog">
    </weapon-transfer-searched-result>


    <weapon-transfer-protokoll
        :dialog-transfer="dialogTransfer"
        :data="WeaponTransfer"
        @clicked="dialogTransfer = false"
    ></weapon-transfer-protokoll>

  </div>
</template>

<script>
import WeaponTable from "@/views/component/weaponTable";
import weaponTransferProtokoll from '@/views/component/weaponTransferProtokoll.vue';
import weaponTransferSearchedResult from '@/views/component/weaponTransferSearchedResult.vue';

export default {
  name: "weapon",
  components: {weaponTransferProtokoll, weaponTransferSearchedResult, WeaponTable},
  data: () => ({
    dialogTransfer: false,
    dialog_Transfer_to: false,
    dialogTransferResults: false,
    dialogTransferReturn: false,
    TextUeberlassung: "",
    rules: {
      required: value => !!value || 'Feld darf nicht leer sein!',
    },
    defaultItem: {
      Beschreibung: null,
      Hersteller: null,
      Kaliber: null,
      KurzT: null,
      LangT: null,
      Nummer: null,
      Erwerb: null,
      Erwerbformated: null,
      export_weapon: false,
      Gattung: {id: 1, value: 'KwGk'},
      NWRWID: null,
      WaffenDisziplin: null,
      weaponID: null,
    },
    WeaponTransfer: {
      Hersteller: '',
      Gattung: '',
      Kaliber: '',
      NWR_WID: '',
      Nummer: '',
      MyName: '',
      MyAddress: '',
      MyPLZ_ORT: '',
      MyNWRPID: '',
      MyWeaponID: '',
      RecName: '',
      RecVorname: '',
      RecAddress: '',
      RecPLZ: '',
      RecOrt: '',
      RecNWRPID: '',
      RecTransferUntil: null,
      RecObject: null,
    },
    WeaponPrepareDelete: {},

    WeaponReturn: {},
  }),

  computed: {
    comp_myWeapons() {
      return this.$store.state.UserApp.Weapons;
    },
    comp_AllDiscipline() {
      return this.$store.state.UserApp.Discipline;
    },
    comp_UserName() {
      return this.$store.state.UserAuth.user.name + ', ' + this.$store.state.UserAuth.user.vorname;
    },
    comp_Anschrift() {
      return this.$store.state.UserAuth.user.strasse;
    },
    comp_PLZ_Ort() {
      return this.$store.state.UserAuth.user.PLZ + ' ' + this.$store.state.UserAuth.user.ort;
    },
    comp_WBK_PID() {
      return this.$store.state.UserAuth.user.NWR_PID;
    },
    comp_Add_Month_to_Transfer_Date() {
      var date = new Date()
      date.setMonth(date.getMonth() + 1)
      date = date.toLocaleString([], {dateStyle: 'medium'});
      return date
    }

  },

  methods: {

    TransferWeaponOpen(item) {
      this.WeaponTransfer.Hersteller = item.Hersteller,
      this.WeaponTransfer.Gattung = item.Gattung,
      this.WeaponTransfer.Kaliber = item.Kaliber,
      this.WeaponTransfer.NWR_WID = item.NWR_WID,
      this.WeaponTransfer.Nummer = item.Nummer,
      this.WeaponTransfer.MyWeaponID = item.id,
      this.WeaponTransfer.MyName = this.comp_UserName,
      this.WeaponTransfer.MyAddress = this.comp_Anschrift,
      this.WeaponTransfer.MyPLZ_ORT = this.comp_PLZ_Ort,
      this.WeaponTransfer.MyNWRPID = this.comp_WBK_PID,
      this.WeaponTransfer.RecTransferUntil = this.comp_Add_Month_to_Transfer_Date

      this.dialog_Transfer_to = true;

    },
    Transfer_get_Reciever() {
      this.dialogTransfer = true;
      this.dialog_Transfer_to = false;
    },
    find_Transfer_Name() {
      let Data = {
        Name: this.WeaponTransfer.RecName
      };
      if (this.WeaponTransfer.RecName !== '') {
        this.$store.dispatch('GeneralApp/search_users_clubs_Names', Data)
            .then(this.dialogTransferResults = true)
      }
    },
    get_Data_from_Result_Dialog(item) {
      this.WeaponTransfer.RecName = item.name,
      this.WeaponTransfer.RecVorname = item.vorname,
      this.WeaponTransfer.RecOrt = item.ort,
      this.WeaponTransfer.RecAddress = item.strasse,
      this.WeaponTransfer.RecPLZ = item.PLZ,
      this.WeaponTransfer.RecOrt = item.ort,
      this.WeaponTransfer.RecNWRPID = item.NWR_PID,
      this.WeaponTransfer.RecObject = item,
      this.WeaponTransfer.RecTransferUntil = this.comp_Add_Month_to_Transfer_Date

      this.dialogTransferResults = false
    },

    TransferWeaponReturnDialog(item) {
      this.WeaponReturn = item;
      this.dialogTransferReturn = true;
    },
    TransferWeaponReturn() {
      let Data = {
        weapon_id: this.WeaponReturn.id,
        transfer_delete: 1
      }
      this.$store.dispatch('GeneralApp/weapon_transfer', Data)
          .then(this.dialogTransferReturn = false)

    },

  },
}
</script>

<style scoped>
</style>

<template>
  <v-btn color="primary" dark="" class="mr-2 ml-2 mb-2" @click="makeWeaponPDF()">
    PDF
    <v-icon right>mdi-file-pdf-outline</v-icon>
  </v-btn>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable';

export default {
  name: 'WeaponPDFExport',
  props: {
    pdf_data: {
      type: Array
    },
    name: {
      type: String
    },
    club_user: {
      type: String
    },
  },
  data: () => ({}),
  methods: {
    makeWeaponPDF() {
      var source = this.pdf_data;
      let rows = [];
      let columnHeader = [];
      if (this.club_user === 'UserApp') {
        columnHeader = ['WBK', 'Beschreibung', 'Erwerb', 'Hersteller', 'Kaliber', 'NWR WID', 'Nummer'];
      } else if (this.club_user === 'ClubApp') {
        columnHeader = ['WBK', 'Beschreibung', 'Erwerb', 'Hersteller', 'Kaliber', 'NWR_WID', 'Nummer'];
      }
      let pdfName = 'Waffen von ' + this.name;
      let pdfDateiName = 'Waffen von ' + this.name;
      let pdfImage = '/Bilder/book.png';
      source.forEach(element => {

        if (element.export_weapon === true || element.export_weapon === 1) {
        var temp = [];
          temp.push(
              element.WBK,
              element.Beschreibung,
              new Date(element.Erwerb.replace(" ", "T")).toLocaleString([], {dateStyle: 'short'}),
              element.Hersteller,
              element.Kaliber,
              element.NWR_WID,
              element.Nummer
          );

          rows.push(temp);
        }
      });

      var doc = new jsPDF({
        orientation: 'landscape'
      });
      // Header
      doc.setFontSize(20);
      doc.setTextColor(40);
      doc.text(pdfName, 10, 22);
      doc.setFontSize(12);
      doc.text("Erstellt am " + new Date().toLocaleString([], {
        dateStyle: 'short',
        timeStyle: 'short'
      }) + " von schuetzenbuch.de", 10, 30);
      doc.addImage(pdfImage, "PNG", 265, 15, 20, 20);


      doc.autoTable(columnHeader, rows, {

        startY: 40,
        margin: {horizontal: 10},
        styles: {overflow: "linebreak"},
        bodyStyles: {valign: "top"},
        columnStyles: {email: {cellWidth: "wrap"}},
        theme: "striped",
        showHead: "everyPage",
        didDrawPage: function (data) {
          // Footer
          var str = "Seite " + doc.internal.getNumberOfPages();

          doc.setFontSize(10);

          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 10);
        }
      });
      // console.log(doc.autoTable.previous);
      doc.save(pdfDateiName + '.pdf');

    },
  },
}
</script>

<style scoped>

</style>
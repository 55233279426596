<template>
<div>
    <v-simple-table height="300px">
        <template v-slot:default>
            <thead>
            <tr>
                <th class="text-left">
                    Disziplinen
                </th>
                <th class="text-left">
                    Action
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in comp_Disciplin_Weapon"
                :key="item.name"
            >
                <td>{{ item.Nummer }} {{ item.KurzBez }} - {{ item.LangBez }}</td>
                <td><v-btn class="error" text @click="deleteDisciplinItem(item)">
                    <v-icon class="mr-2">
                        mdi-delete
                    </v-icon>
                    Löschen
                </v-btn></td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>

</div>
</template>

<script>
export default {
    props: {
        waffen_id: Number,
    },
    name: "WeaponDiscipline",
    data: () => ({

    }),
    computed: {
        comp_Disciplin_Weapon() {
          return this.$store.getters['UserApp/weapon_Disciplines'](this.waffen_id);
        },
    },
    methods: {

        deleteDisciplinItem(item) {
          let Data = {
            waffenID: item.pivot.weapon_id,
            DisziID: item.pivot.discipline_id
          }
          this.$store.dispatch('UserApp/delDisciplinWeapon', Data)
        },
    },

}
</script>

<style scoped>

</style>
